import React, { useEffect } from "react";
import ReactLoading from "react-loading";

//utils
import { clearStorages } from "../../../../utils/Storage";

const LogoutPage = () => {
  useEffect(() => {
    clearStorages();
    setTimeout(() => {
      window.location.href = "/";
    }, 2000);
  }, []);
  return (
    <>
      <div
        id="page-404"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 80px)",
          flexDirection: "column",
        }}
      >
        <ReactLoading color="#000" type="bubbles" width={100} />
        <span style={{ fontSize: "20px" }}>Saindo...</span>
      </div>
    </>
  );
};

export default LogoutPage;
