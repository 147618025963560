const CONST = {
  //APPLICATION
  SOME_INFORMATION_EMPTY: "Alguma informação preenchida incorretamente",
  COMMUNICATION_FAIL: "Falha de comunicação, tente novamente!",
  INPUT_EMPTY: "Campo está vazio",
  ALL_RIGHTS_RESERVED: "Todos os direitos reservados",

  //AUTH
  APP_ACCESS_APRROVED: "Acesso autorizado. Aguarde...",
  APP_ACCESS_DANIED: "Usuário ou senha inválidos",
  INPUT_MINMAX_SIZE_8_150: "Campo deve conter entre 8 e 150 caracteres",
  INPUT_MINMAX_SIZE_8_64: "Campo deve conter de 8 a 64 caracteres",
  INVALID_EMAIL: "Informe um email válido",
};

export default CONST;
