/**
 * Cria o título da página web
 * @param {array} arrayTitles -Recebe um array com o nome das telas e ordena pela sequência
 */
export const defineTitlePape = () => {
  let titleName = "";
  const titles = [
    { key: "/login", title: "Login" },
    { key: "/settings", title: "Configurações" },
  ];

  titles.forEach((tt) => {
    if (window.location.href.indexOf(tt.key) >= 0) {
      titleName = tt.title;
    }
  });

  document.title = titleName !== "" ? "Prolter | " + titleName : "Prolter";
};
