import React, { useContext, useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";

//styles
import "../../../assets/styles/components/app/header-app.css";

//images
import LogoLogon from "../../../assets/images/application/prolter-logo-icon.svg";
import LogoLogoff from "../../../assets/images/application/prolter-logo-landscape-white.svg";

//providers
import { Context } from "../../../providers/authProvider";

const HeaderApp = () => {
  const { user, authenticated } = useContext(Context);
  const [menuOpen, setMenuOpen] = useState("static");
  const [links, setLinks] = useState([]);

  useEffect(() => {
    const urlItems = [
      { url: "providers", label: "Provedores" },
      { url: "clients", label: "Clientes" },
      { url: "support", label: "Suporte" },
      { url: "finance", label: "Financeiro" },
      { url: "reports", label: "Relatórios" },
      { url: "users", label: "Usuário" },
      { url: "settings", label: "Configurações" },
      { url: "enterprise", label: "Empresas", dev: true },
      { url: "plans", label: "Planos", dev: true },
      { url: "logout", label: "Sair" },
    ];

    let extractedUrls = [];

    urlItems.forEach((u) => {
      if (u?.dev) {
        if (user && user.account_type === "D") {
          extractedUrls.push(u);
        }
      } else {
        extractedUrls.push(u);
      }
    });

    setLinks(extractedUrls);
    // eslint-disable-next-line
  }, []);

  const activeMenu = () => {
    if (menuOpen === "static") {
      setMenuOpen("active");
    } else if (menuOpen === "active") {
      setMenuOpen("inactive");
    } else {
      setMenuOpen("active");
    }
  };

  function layoutLogon() {
    return (
      <>
        <a href="/home" className="logo-on">
          <img src={LogoLogon} alt="Logo Prolter" />
        </a>
        <div className="btn-menu-on" onClick={() => activeMenu()}>
          <span></span>
        </div>
        {menuOpen === "active" && (
          <div className="menu-prolter-mask" onClick={() => activeMenu()}></div>
        )}
        <div className={`menu-prolter ${menuOpen}`}>
          <FaTimes className="close" onClick={() => activeMenu()} />
          <span>
            <img src={LogoLogoff} alt="Logo Prolter" />
          </span>

          {links.map((link) => (
            <a
              href={`/${link.url}`}
              className={link?.dev ? "dev" : ""}
              key={`menu-links-${link.url}`}
            >
              {link.label}
            </a>
          ))}
        </div>
      </>
    );
  }

  function layoutLogoff() {
    return (
      <>
        <a href="/" className="logo-off">
          <img src={LogoLogoff} alt="Logo Prolter" />
        </a>
        <div className="menu-off">
          <a href="/business">Empresa</a>
          <a href="/recursos">Recursos</a>
          <a href="/comercial">Comercial</a>
        </div>
      </>
    );
  }

  return (
    <header id="header-app">
      {authenticated && layoutLogon()}
      {!authenticated && layoutLogoff()}
    </header>
  );
};

export default HeaderApp;
