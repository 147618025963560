//ROTAS DE APLICAÇÃO
import LandPage from "../pages/application/general/land";
import LoginPage from "../pages/application/auth/login";
import LogoutPage from "../pages/application/auth/logout";
import HomePage from "../pages/application/general/home";
import EnterpriseHomePage from "../pages/application/enterprise/home";
import Page404 from "../pages/application/general/404";
import NoPermissionPage from "../pages/application/general/nopermission";

export const listRoutes = () => {
  return [
    //GENERAL
    { path: "/", page: LandPage },
    { path: "/home", page: HomePage, auth: true },

    //AUTH
    { path: "/login", page: LoginPage },
    { path: "/logout", page: LogoutPage },
    { path: "/nopermission", page: NoPermissionPage },

    //ENTERRPISE
    { path: "/enterprise", page: EnterpriseHomePage, auth: true, dev: true },

    //NO MATCH
    { path: "*", page: Page404 },
  ];
};

export const activeRoute = () => {
  let route = window.location.pathname;
  let routeSelected = null;
  listRoutes().forEach((element) => {
    if (element.path === route || route.indexOf(element.id) >= 0) {
      routeSelected = element;
      return;
    }
  });
  return routeSelected || { path: "*", page: Page404 };
};
