import React, { useState } from "react";
import _v from "validator";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Card, Button, Form, Input } from "antd";

//icons
import LogoColorFull from "../../../../assets/images/application/prolter-logo-landscape-colorfull.svg";

//redux
import { handleLogin } from "../../../../store/application/auth/auth.action";

//utils
import i18n from "../../../../utils/i18n/pt-br";

//styles
import "../../../../assets/styles/auth/login.css";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    let error = "";

    //valida os valores digitados
    if (_v.isEmpty(username) || _v.isEmpty(password)) {
      error = i18n.INPUT_EMPTY;
    } else if (!_v.isLength(username, { min: 8, max: 150 })) {
      error = i18n.INPUT_MINMAX_SIZE_8_150;
    } else if (username.indexOf("@") >= 0 && !_v.isEmail(username)) {
      error = i18n.INVALID_EMAIL;
    } else if (!_v.isLength(password, { min: 8, max: 64 })) {
      error = i18n.INPUT_MINMAX_SIZE_8_64;
    }

    if (error === "") {
      dispatch(
        handleLogin({
          username,
          password,
        })
      );
    } else {
      toast.error(error);
    }
  };

  return (
    <div id="login-page">
      <div className="login-box">
        <Card style={{ width: "300px" }} className="form">
          <div className="logo">
            <img src={LogoColorFull} alt="Logo Prolter" />
          </div>
          <Form layout="vertical">
            <Form.Item label="Email/Usuário" name="username" id="username">
              <Input
                onChange={({ target: { value } }) => setUsername(value)}
                value={username}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item label="Senha" name="password" id="password">
              <Input.Password
                onChange={({ target: { value } }) => setPassword(value)}
                value={password}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                onClick={(e) => handleSubmit(e)}
              >
                ENTRAR
              </Button>
            </Form.Item>
          </Form>
          <div className="footer-login">
            <span style={{ color: "red" }}>&hearts;</span> Prolter 2024
            <br />
            {i18n.ALL_RIGHTS_RESERVED}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default LoginPage;
