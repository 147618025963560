import axios from "axios";

import { getStorage } from "../utils/Storage";

const hostname = window.location.hostname;

let baseURL =
  hostname.indexOf("prolter") >= 0
    ? "https://www.prolter.pro/api"
    : "http://localhost:3333/api";

export default axios.create({ baseURL });

const token = (() => {
  return getStorage("PROLTER-TOKEN");
})();

const company = (() => {
  return getStorage("PROLTER-COMPANY");
})();

export const authorization = (() => {
  return {
    authorization: `Bearer ${token !== "" ? token : ""}`,
  };
})();

/**
 * Retorna o header com dados de autenticação
 * @param {string} contentHeader.authorization - Autorização de segurança
 * @param {number} contentHeader.company - ID da empresa
 * @returns {Object}
 */
export const headers = (() => {
  let contentHeader = {};
  if (token !== "" && company !== "") {
    contentHeader.authorization = `Bearer ${token !== "" ? token : ""}`;
    contentHeader.company = company;
  }
  return contentHeader;
})();
