import { configureStore } from "@reduxjs/toolkit";
import system from "./application/system/system.slice";
import auth from "./application/auth/auth.slice";
import user from "./application/user/user.slice";

export default configureStore({
  reducer: {
    system,
    auth,
    user,
  },
});
