import React from "react";
import { Card } from "antd";

const EnterpriseHomePage = () => {
  return (
    <div id="enterprise-page">
      <Card
        title="Empresas"
        className="content-1200"
        styles={{ body: { padding: 0 } }}
      >
        Empresas
      </Card>
    </div>
  );
};

export default EnterpriseHomePage;
