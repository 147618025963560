import React from "react";

import icon404 from "../../../../assets/images/broken404.svg";

const page404 = () => {
  return (
    <div
      id="page-404"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 60px)",
      }}
    >
      <img src={icon404} alt="icon404" width={200} />
    </div>
  );
};

export default page404;
