import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "auth",
  initialState: {
    provider: null,
  },
  reducers: {
    setProvider(state, { payload }) {
      state.provider = payload;
    },
  },
});

export const { setProvider } = slice.actions;

export const provider = (state) => state.auth.provider;

export default slice.reducer;
