import React from "react";

//styles
import "../../../assets/styles/components/app/content-app.css";

const ContentApp = ({ children, auth }) => {
  return (
    <article id="content-app" className={auth ? `padding-10` : ``}>
      {children}
    </article>
  );
};

export default ContentApp;
