import React from "react";
import { useSelector } from "react-redux";

//images
import LoadingIon from "../../../assets/images/application/loading.svg";

//styles
import "./styles.css";

//redux
import { loadingRX } from "../../../store/application/system/system.slice";

const Loading = () => {
  const loading = useSelector(loadingRX);

  return (
    <>
      {loading && (
        <div className="full-loading-page">
          <img src={LoadingIon} alt="Carregando.." />
        </div>
      )}
    </>
  );
};

export default Loading;
