import { showLoading } from "../system/system.actions";
import { loginAPI } from "../auth/auth.service";
import i18n from "../../../utils/i18n/pt-br";
import { setStorage } from "../../../utils/Storage";
import { toast } from "react-toastify";

export const handleLogin = (loginData) => {
  return (dispatch) => {
    dispatch(showLoading(true));
    loginAPI(loginData)
      .then(({ data }) => {
        setStorage("PROLTER-TOKEN", data);
        toast.success(i18n.APP_ACCESS_APRROVED);
        setTimeout(() => {
          window.location = "/home";
        }, 2000);
      })
      .catch(() => {
        dispatch(showLoading(false));
        toast.error(i18n.APP_ACCESS_DANIED);
      });
  };
};
