import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";

//providers
import { Context } from "../providers/authProvider";

//routes
import { listRoutes, activeRoute } from "./all";

//componets
import TemplateApp from "../components/app/TemplateApp";

//utils
import { defineTitlePape } from "../utils/Strings";

const MainRoutes = () => {
  defineTitlePape();
  const { loading, user, authenticated } = useContext(Context);
  const allRoutes = listRoutes();
  const { auth, dev } = activeRoute();
  let match = window.location.pathname;

  // primeiro momento que entra no app. "splashscreen"
  if (loading) {
    return <div id="splash-message">Carregando...</div>;
  } else {
    //CLIENT
    if (user !== null && user.app === "client") {
      if (authenticated && match === "/login") {
        window.location.href = "/client";
        return;
      }
    } else {
      //APPLICATION
      if (authenticated && match === "/login") {
        window.location.href = "/home";
        return;
      }

      if (auth) {
        if (authenticated) {
          if (dev && user.account_type !== "D") {
            window.location.href = "/nopermission";
            return;
          }
        } else {
          window.location = "/login";
          return;
        }
      }
    }

    return (
      <TemplateApp>
        <Routes>
          {allRoutes.map((e, index) => {
            return <Route path={e.path} key={index} element={<e.page />} />;
          })}
        </Routes>
      </TemplateApp>
    );
  }
};

export default MainRoutes;
