import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "user",
  initialState: {
    users: null,
  },
  reducers: {
    setUsers(state, { payload }) {
      state.users = payload;
    },
  },
});

export const { setUsers } = slice.actions;

export const usersRX = (state) => state.user.users;

export default slice.reducer;
