import React from "react";

import { LockOutlined } from "@ant-design/icons";

//styles
import "../../../../assets/styles/general/nopermission.css";

const NoPermissionPage = () => {
  return (
    <div id="nopermission-page">
      Acesso Negado!
      <LockOutlined />
      Necessário ter permissão para acessar essa tela
    </div>
  );
};

export default NoPermissionPage;
