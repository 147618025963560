import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";

//providers
import { AuthProvider } from "./providers/authProvider";

//global styles
import "../src/assets/styles/global/main.css";
import "../src/assets/styles/global/ui.css";
import "react-toastify/dist/ReactToastify.css";

//routes
import AppRoutes from "./routes";

//redux
import store from "./store";

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#081124",
            },
          }}
        >
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </ConfigProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
