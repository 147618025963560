import React, { useContext } from "react";
import { ToastContainer } from "react-toastify";

//components
import HeaderApp from "../HeaderApp";
import ContentApp from "../ContentApp";
import Loading from "../Loading";

//providers
import { Context } from "../../../providers/authProvider";

const TemplateApp = ({ children }) => {
  const { authenticated } = useContext(Context);

  return (
    <>
      <Loading />
      <ToastContainer
        position="top-center"
        closeOnClick
        pauseOnHover
        autoClose={2000}
      />
      <HeaderApp />
      <ContentApp auth={authenticated}>{children}</ContentApp>
    </>
  );
};

export default TemplateApp;
